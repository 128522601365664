import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HeaderMenuComponent } from './header-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatListModule,
        MatIconModule,
        MatBadgeModule,
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        SharedModule,
        ScrollingModule,
    ],
    declarations: [HeaderMenuComponent],
    providers: [],
    exports: [
        HeaderMenuComponent,
    ],
})
export class HeaderMenuModule
{
}
