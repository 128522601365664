import { NgModule } from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PmcLayoutComponent } from './pmc-layout.component';
import { pmcLayoutRouting } from './pmc-layout.routing';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeaderSearchModule } from './header/header-search/header-search.module';
import { SharedModule } from '../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SidenavNavigationModule } from './sidenav/sidenav-navigation/sidenav-navigation.module';
import { MatListModule } from '@angular/material/list';
import { HeaderMenuModule } from './header/header-menu/header-menu.module';

@NgModule({
    imports: [
        pmcLayoutRouting,
        SharedModule,
        MatSidenavModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        HeaderSearchModule,
        HeaderMenuModule,
        SidenavNavigationModule,
    ],
    declarations: [PmcLayoutComponent],
    exports: [],
})
export class PmcLayoutModule
{
}
