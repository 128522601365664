import { Injectable } from '@angular/core';
import { Routes } from '../../config/routes';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import environment from '../../../environments/environment';

export interface Hit
{
    id: number;
    index: 'units' | 'members';
    organizationId: number;
}

@Injectable({
    providedIn: 'root'
})
export class ElasticSearchService
{
    searchString: string = '';
    searchResults: BehaviorSubject<Hit[]> = new BehaviorSubject<Hit[]>([]);

    constructor(
            private _http: ApplicationHttpClient,
    ) {
    }

    /**
     * Handle the Algolia search
     * @param searchString
     * @param limit
     */
    public search(searchString: string, limit: number): Observable<Hit[]> {
        const url = Routes.MakeLegFiCoreUrl('/search') + this._http.queryStringFromObject({
            term: searchString,
            limit: limit,
        });

        this.searchString = searchString;
        if (!environment.production) {
            return this.mockSearch();
        }

        return this._http.get(url).pipe(map((response: Hit[]) => {
            this.searchResults.next(response);
            return response;
        }));
    }

    private mockSearch(): Observable<Hit[]> {
        const searchResults: any = [
            {
                id: 12345,
                organizationId: 999999,
                title: 'Test Unit Result 01',
                description: '',
                index: 'units',
            },
            {
                id: 12346,
                organizationId: 10101010,
                title: 'Test Unit Result 02',
                description: '',
                index: 'units',
            },
            {
                id: 98765,
                organizationId: 999999,
                index: 'members',
                profile: {
                    membershipId: 123,
                    givenNames: 'Doe,',
                    familyName: 'John',
                },
            },
            {
                id: 564321,
                organizationId: 41234123,
                index: 'members',
                profile: {
                    membershipId: 456,
                    givenNames: 'Smith,',
                    familyName: 'Jane',
                },
            },
        ];
        return of(searchResults).pipe(delay(1000), map((response: Hit[]) => {
            this.searchResults.next(response);
            return response;
        }));
    }
}
