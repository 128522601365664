import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import environment from '../environments/environment';
import { AppRoutingModule } from './app.routing'; // Custom Route Paths
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlimLoaderModule } from './components/shared/slim-loader/slim-loader.module';
import { LoginGuard } from './common/auth/login.guard';
import { AdminLayoutModule } from './components/admin-layout/admin-layout.module';
import { AuthLayoutModule } from './components/auth-layout/auth-layout.module';
import { ErrorModule } from './components/error/error.module';
import { MarketingLayoutModule } from './components/marketing-layout/marketing-layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatedBrowserModule } from './components/shared/dated-browser/dated-browser.module';
import { NgxMaskModule } from 'ngx-mask';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { DragulaModule } from 'ng2-dragula';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyFieldFileComponent } from './directives/formly-field-file.component';
import { FormlyFileValueAccessorDirective } from './directives/formly-file-value-accessor.directive';
import { FormlyFieldTextareaComponent } from './directives/formly-field-textarea.component';
import { FileUploadModule } from 'ng2-file-upload';
import { UnlimitedGuard } from './common/auth/unlimited.guard';
import { ForwardToFormsGuard } from './common/auth/forward-to-forms.guard';
import { SiteIdInterceptor } from './components/shared/http/site-id.interceptor';
import { ContentTypeInterceptor } from './components/shared/http/content-type.interceptor';
import { RefreshTokenInterceptor } from './components/shared/http/refresh-token.interceptor';
import { EchoInterceptor } from './components/shared/http/echo.interceptor';
import { ApplicationHttpClient, applicationHttpClientFactory } from './components/shared/http/application-http-client';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppLayoutModule } from './components/app-layout/app-layout.module';
import { AppLayoutModulePayhoa } from './components/app-layout/app-layout.module.payhoa';
import { PixelModule } from 'ngx-pixel';
import { TagsModule } from './components/shared/tags/tags.module';
import { PermissionsGuard } from './common/auth/permissions.guard';
import { MaintenanceModeInterceptor } from './components/shared/http/maintenance-mode.interceptor';
import { MaintenanceModeDialogModule } from './components/app-layout/shared/maintenance-mode-dialog/maintenance-mode-dialog.module';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxStripeModule } from 'ngx-stripe';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { NgxsModule } from '@ngxs/store';
import { ToastaModule } from 'ngx-toasta';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { EditorModule } from '@tinymce/tinymce-angular';
import { RichTextEditorModule } from './components/shared/rich-text-editor/rich-text-editor.module';
import { TrackingService } from './services/tracking.service';
import { IdleTimeoutDialogModule } from './components/shared/idle-timeout-dialog/idle-timeout-dialog.module';
import { PmcLayoutModule } from './components/pmc-layout/pmc-layout.module';

export function appFactory() {
    return (): Promise<any> => {
        // reset tracking on app init
        if (TrackingService.read()) {
            TrackingService.clear();
        }
        return new Promise(((resolve, reject) => resolve(true)));
    };
}

@NgModule({
    declarations: [
        FormlyFileValueAccessorDirective,
        FormlyFieldFileComponent,
        FormlyFieldTextareaComponent,
        AppComponent,
    ],
    imports: [
        AppLayoutModulePayhoa,
        BrowserModule,
        BrowserAnimationsModule,
        DatedBrowserModule,
        EditorModule,
        RichTextEditorModule,
        FormsModule,
        HttpClientModule,
        HttpClientXsrfModule,
        PixelModule.forRoot({
            enabled: true,
            pixelId: '2368280270075523',
        }),
        AgmCoreModule.forRoot({
            apiKey: environment.GoogleMapsApiKey,
            libraries: ['places'],
        }),
        NgbModule,
        AdminLayoutModule,
        PmcLayoutModule,
        AppLayoutModule,
        AuthLayoutModule,
        DragulaModule.forRoot(),
        FormlyModule.forRoot(
                {
                    validationMessages: [
                        {name: 'required', message: 'This field is required'},
                    ],
                    types: [
                        {name: 'file', component: FormlyFieldFileComponent, wrappers: ['form-field']},
                        {name: 'textarea', component: FormlyFieldTextareaComponent, wrappers: ['form-field']},
                    ],
                },
        ),
        FormlyBootstrapModule,
        MarketingLayoutModule,
        ErrorModule,
        SlimLoaderModule,
        BrowserAnimationsModule,
        NgxMaskModule.forRoot(),
        ReactiveFormsModule,
        FileUploadModule,
        TagsModule,
        MaintenanceModeDialogModule,
        NgxStripeModule.forRoot(environment.StripePublishableKey, {
            betas: ['blocked_card_brands_beta_2'],
        }),
        GoogleTagManagerModule.forRoot({
            id: environment.GoogleGtmContainerId,
        }),
        MatGoogleMapsAutocompleteModule,
        NgxsModule.forRoot([], {
            developmentMode: !environment.production,
            selectorOptions: {
                suppressErrors: false,
                injectContainerState: false,
            },
        }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        ToastaModule.forRoot(),

        // Always import app routing last!
        AppRoutingModule,
        IdleTimeoutDialogModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SiteIdInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MaintenanceModeInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ContentTypeInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EchoInterceptor,
            multi: true,
        },
        {
            provide: ApplicationHttpClient,
            useFactory: applicationHttpClientFactory,
            deps: [
                HttpClient,
                Location,
                Router,
            ],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appFactory,
            deps: [],
            multi: true,
        },
        UnlimitedGuard,
        LoginGuard,
        ForwardToFormsGuard,
        PermissionsGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule
{
}
