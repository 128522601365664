import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { filter, Observable } from 'rxjs';
import { PropertyManagementCompanyService } from '../../services/property-management-company/property-management-company.service';
import { PropertyManagementCompany } from '../../models/entities/property-management-company';
import { SvgIconService } from '../../services/svg-icon.service';
import { PropertyManagementCompanyDataService } from '../../services/property-management-company/property-manager-company-data.service';

@UntilDestroy()
@Component({
    selector: 'lf-pmc-layout',
    styleUrls: ['./pmc-layout.component.scss'],
    templateUrl: './pmc-layout.component.html',
})
export class PmcLayoutComponent implements OnInit, AfterViewInit
{
    pmc$: Observable<PropertyManagementCompany>;
    isInMobileMode = false;

    @ViewChild(MatSidenav) sidenav!: MatSidenav;

    readonly appLogoSrc = '/assets/images/v2/payhoa/logo.svg';

    constructor(
            private _propertyManagementCompanyService: PropertyManagementCompanyService,
            private _propertyManagementCompanyData: PropertyManagementCompanyDataService,
            private _activatedRoute: ActivatedRoute,
            private _observer: BreakpointObserver,
            private _svg: SvgIconService,
    ) {
        // For now, use v2 svg icons for pmc dashboard
        this._svg.registerV2SvgIcons();
    }

    ngOnInit() {
        this.pmc$ = this._propertyManagementCompanyData.pmc$;
        this._propertyManagementCompanyData.clear();

        this._activatedRoute.paramMap.pipe(
                map((params) => +params.get('pmcId')),
                filter((pmcId) => !!pmcId),
                switchMap((pmcId) => this._propertyManagementCompanyService.getPropertyManagementCompany(pmcId)),
                untilDestroyed(this),
        ).subscribe({
            next: (pmc) => this._propertyManagementCompanyData.switch(pmc),
        });
    }

    /** Set up mat sidebar / menu functionality based on screen width */
    ngAfterViewInit() {
        setTimeout(() => {
            this._observer.observe(['(max-width: 945px)']).subscribe((res) => {
                if (res.matches) {
                    this.sidenav.mode = 'over';
                    this.sidenav.close().then(() => {
                        this.isInMobileMode = true;
                    });

                } else {
                    this.sidenav.mode = 'side';
                    this.sidenav.open().then(() => {
                        this.isInMobileMode = false;
                    });
                }
            });
        });
    }

    // if the page is in mobile view, they clicked to change routes; toggle the sidenav
    handleSidenavClickOnMobile(): void {
        if (this.sidenav.mode === 'over') {
            this.sidenav.close();
        }
    }
}
