import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Unit } from '../../../../models/entities/unit';
import { Membership } from '../../../../models/entities/membership';
import { DropdownSearchType } from '../../../shared/search/dropdown-search/dropdown-search.component';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, iif, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ElasticSearchService } from '../../../../services/search/elastic-search.service';
import { GrowlerService } from '../../../../services/growler.service';

export interface DropdownSearchItem
{
    index: DropdownSearchType;
    model: Unit | Membership;
}

@UntilDestroy()
@Component({
    selector: 'app-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: [
        '../../pmc-layout.component.scss',
        './header-search.component.scss',
    ],
})
export class HeaderSearchComponent implements OnInit
{
    @Output() resultSelected: EventEmitter<any> = new EventEmitter();

    results: DropdownSearchItem[] = [];
    control = new FormControl('');
    searchingState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isSearching = false;

    private abortPreviousSearch: Subject<void> = new Subject();

    constructor(
            private _searchService: ElasticSearchService,
            private _growler: GrowlerService,
    ) {
    }

    get filteredResults(): DropdownSearchItem[] {
        return this.results.filter((item) => {
            const value = this.getValue(item).trim().toLowerCase();
            return value.indexOf(this._searchService.searchString) > -1;
        });
    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe({
            next: () => this.search(),
        });

        this.searchingState$.asObservable().pipe(
                switchMap((loading) => iif(() => loading, of(loading), of(loading).pipe(delay(100)))),
                untilDestroyed(this),
        ).subscribe({
            next: (searching) => this.isSearching = searching,
        });
    }

    getValue(result: DropdownSearchItem): string {
        switch(result.index) {
            case 'units':
                return (result.model as Unit).title;
            case 'members':
                return (result.model as Membership).profile.name;
        }
    }

    search() {
        const inputValue = this.control.value.trim().toLowerCase();
        if (this.isSearching || this._searchService.searchString === inputValue) {
            return;
        }

        this.abortPreviousSearch.next();
        this.searchingState$.next(true);
        this._searchService.search(inputValue, 3).pipe(
                takeUntil(this.abortPreviousSearch),
                untilDestroyed(this),
        ).subscribe({
            next: (hits) => {
                // TODO: ngxs managed search results state?
                this.results = hits.map((hit) => {
                    let model: Unit | Membership;
                    switch (hit.index) {
                        case 'units':
                            model = new Unit(hit);
                            break;
                        case 'members':
                            model = new Membership(hit);
                            break;
                    }
                    return {
                        index: hit.index,
                        model,
                    };
                });
            },
            error: () => this._growler.oops('There was a problem loading search results.'),
            complete: () => this.searchingState$.next(false),
        });
    }
}
