import { map } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import moment from 'moment-timezone';
import { Routes } from '../../config/routes';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Charge } from '../../models/entities/charge';
import { ChargeForm } from './charge-form.model';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { RecurringChargeTemplate } from '../../models/entities/recurring-charge-template';
import { RecurringChargeTemplateUnit } from 'app/models/entities/recurring-charge-template-unit';
import { ExpectedCharge } from 'app/models/entities/expected-charge';
import { ChargeDatasourceOptions, ChargesResponse } from './charges.datasource';
import { Unit } from '../../models/entities/unit';
import { RecurringCharge } from '../../models/entities/recurring-charge';
import { GtmService } from '../gtm.service';

export interface RecurringTemplateDiffResponse
{
    template1: {
        template: RecurringChargeTemplate,
        units: Unit[]
    };
    template2: {
        template: RecurringChargeTemplate,
        units: Unit[]
    };
}

@Injectable({
    providedIn: 'root',
})
export class ChargesService
{
    public editCharge$ = new Subject<number>();
    public deleteCharges$ = new Subject<number[]>();
    public chargesArchived$ = new Subject<void>();
    public recordPaymentForCharges$ = new Subject<Charge[]>();
    public createPaymentPlanForCharges$ = new Subject<Charge[]>();
    public monitorIntervals: any = {};
    public jobCompleted: EventEmitter<{
        job: string,
        id: number,
        running: boolean,
        status: string
    }> = new EventEmitter<{ job: string; id: number, running: boolean, status: string }>();
    public lastJobStatus: any = {};

    constructor(
            private _http: ApplicationHttpClient,
            private _gtm: GtmService,
    ) {
    }

    /**
     * @param {any} rows
     * @returns {any}
     */
    public static castDatesToMoment(rows: any) {
        if (rows && rows.length) {
            for (const c in rows) {
                if (rows.hasOwnProperty(c)) {
                    const timezone = LegFiJwtService.getTimezone();
                    const charge = (<Charge>rows[c]);

                    if (charge.activeAfter) {
                        charge.activeAfter = moment.utc(charge.activeAfter, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
                    }
                    if (charge.paymentDueOn) {
                        charge.paymentDueOn = moment.utc(charge.paymentDueOn, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
                    }

                    if (charge.earlyBefore !== null) {
                        charge.earlyBefore = moment.utc(charge.earlyBefore, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
                    }

                    if (charge.lateAfter !== null) {
                        charge.lateAfter = moment.utc(charge.lateAfter, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
                    }

                    rows[c] = charge;
                }
            }
        }

        return rows;
    }

    /**
     * @param {ChargeForm[]} chargeForms
     * @param {string} payorType
     * @returns {Charge[]}
     */
    public static chargeFormListToCharges(chargeForms: ChargeForm[], payorType: string) {
        if (!(chargeForms && chargeForms.length)) {
            return [];
        }

        // Convert
        let outputCharges = [];
        for (let c = 0; c < chargeForms.length; c++) {
            if (!chargeForms[c].recurring) {
                outputCharges = outputCharges.concat(ChargesService.chargeFormToCharges(chargeForms[c], payorType));
            }
        }

        return outputCharges;
    }

    /**
     * @param {ChargeForm[]} chargeForms
     * @param {string} payorType
     * @returns {RecurringChargeTemplate[]}
     */
    public static chargeFormListToRecurringCharges(chargeForms: ChargeForm[], payorType: string) {
        if (!(chargeForms && chargeForms.length)) {
            return [];
        }

        // Convert
        let outputCharges = [];
        for (let c = 0; c < chargeForms.length; c++) {
            if (chargeForms[c].recurring) {
                outputCharges = outputCharges.concat(ChargesService.chargeFormToRecurringCharges(chargeForms[c], payorType));
            }
        }

        return outputCharges;
    }

    /**
     * @param {Charge} charge
     * @returns {ChargeForm}
     */
    public static chargeToChargeForm(charge: Charge) {
        return <ChargeForm>{
            id: charge.id,
            organizationId: charge.organizationId,
            depositBankAccountId: charge.depositBankAccountId,
            categoryId: charge.categoryId,
            title: charge.title,
            description: charge.description,
            emailAppendMessage: charge.emailAppendMessage,
            currency: charge.currency,
            chargeAmount: charge.chargeAmount,
            paid: charge.paid,
            activeAfter: charge.activeAfter,
            paymentDueOn: charge.paymentDueOn,
            earlyBefore: charge.earlyBefore,
            earlyDiscount: charge.earlyDiscount,
            lateAfter: charge.lateAfter,
            lateFee: charge.lateFee,
            lateFees: charge.lateFees,
            reason: '',
            deletedAt: charge.deletedAt,
            recurring: false,
            variablePricing: false,
            payors: [{id: charge.payorId, chargeAmount: charge.chargeAmount, title: charge.payor?.name}] || [],
            emailInvoice: charge.emailInvoice,
            endDate: null,
            recurBasis: null,
            recursOn: null,
            recursEvery: null,
            lateAfterDays: null,
            earlyBeforeDays: null,
        };
    }

    /**
     * @param {RecurringChargeTemplate} template
     * @returns {ChargeForm}
     */
    public static recurringChargeTemplateToChargeForm(template: RecurringChargeTemplate) {
        return <ChargeForm>{
            id: template.id,
            organizationId: template.organizationId,
            depositBankAccountId: template.depositBankAccountId,
            categoryId: template.categoryId,
            title: template.title,
            description: template.description,
            emailAppendMessage: template.emailAppendMessage,
            currency: template.currency,
            chargeAmount: template.chargeAmount,
            activeAfter: template.startDate,
            paymentDueOn: template.firstDueDate,
            endDate: template.endDate,
            earlyBeforeDays: template.earlyBeforeDays,
            earlyDiscount: template.earlyDiscount,
            lateAfterDays: template.lateAfterDays,
            lateFee: template.lateFee,
            lateFees: template.lateFees,
            deletedAt: template.deletedAt,
            recurBasis: template.recurBasis,
            recursOn: template.recursOn,
            recursEvery: template.recursEvery,
            recurring: true,
            variablePricing: new Set(template.templateUnits.map(unit => unit.amount)).size > 1,
            payors: template.templateUnits.map(unit => ({
                id: unit.unitId,
                chargeAmount: unit.amount,
                title: unit.unit.title,
            })).sort((a, b) => a.title.localeCompare(b.title)),
            emailInvoice: template.emailInvoice,
        };
    }

    /**
     * @param {ChargeForm} chargeForm
     * @param {string} payorType
     * @returns {Charge[]}
     */
    public static chargeFormToCharges(chargeForm: ChargeForm, payorType: string) {
        const charges: Charge[] = [];

        // For each payor listed for charge.
        chargeForm.payors.forEach((payor) => {
            if (!chargeForm.recurring) {
                // Non-recurring charge.
                const tempCharge = ChargesService.getBaseChargeFromChargeForm(chargeForm);

                // Copy payor information in.
                tempCharge.payorId = payor.id;
                tempCharge.payorType = payorType;

                // Use variable pricing charge amount spec.
                if (chargeForm.variablePricing) {
                    tempCharge.chargeAmount = payor.chargeAmount;
                }

                // Delete early discount fields if no discount applies.
                if (!(tempCharge.earlyBefore && tempCharge.earlyDiscount)) {
                    delete tempCharge.earlyBefore;
                    delete tempCharge.earlyDiscount;
                }
                // Delete late fee fields if no late fee applies.
                if (!(tempCharge.lateAfter && tempCharge.lateFee)) {
                    delete tempCharge.lateAfter;
                    delete tempCharge.lateFee;
                }

                if (tempCharge.lateFees) {
                    for (let i = 0; i < tempCharge.lateFees.length; i++) {
                        if (tempCharge.lateFees[i].id === null) {
                            delete tempCharge.lateFees[i].id;
                        }
                        delete tempCharge.lateFees[i].lateAfterDays;
                        if (tempCharge.lateFees[i].lateFeeType === 'one-time') {
                            delete tempCharge.lateFees[i].recurringLateFeeBegins;
                            delete tempCharge.lateFees[i].recurringLateFeeInterval;
                            delete tempCharge.lateFees[i].recurringLateFeeIntervalValue;
                            delete tempCharge.lateFees[i].recurringLateFeeType;
                            delete tempCharge.lateFees[i].recurringLateFeeAmount;
                        } else {
                            delete tempCharge.lateFees[i].oneTimeLateFeeType;
                            delete tempCharge.lateFees[i].oneTimeLateFeeApplies;
                            delete tempCharge.lateFees[i].oneTimeLateFeeAmount;
                        }
                    }
                }

                charges.push(tempCharge);
            }
        });

        return charges;
    }

    /**
     * @param {ChargeForm} chargeForm
     * @param {string} payorType
     * @returns {RecurringChargeTemplate[]}
     */
    public static chargeFormToRecurringCharges(chargeForm: ChargeForm, payorType: string) {
        if (!chargeForm.recurring) {
            return [];
        }
        const tempCharge = new RecurringChargeTemplate(chargeForm);
        tempCharge.payorType = payorType;
        chargeForm.payors.forEach((payor) => {
            tempCharge.templateUnits.push(new RecurringChargeTemplateUnit({
                unitId: payor.id,
                amount: chargeForm.variablePricing ? payor.chargeAmount : chargeForm.chargeAmount,
            }));
        });

        // Delete early discount fields if no discount applies.
        if (!(tempCharge.earlyBeforeDays && tempCharge.earlyDiscount)) {
            delete tempCharge.earlyBeforeDays;
            delete tempCharge.earlyDiscount;
        }
        // Delete late fee fields if no late fee applies.
        if (!(tempCharge.lateAfterDays && tempCharge.lateFee)) {
            delete tempCharge.lateAfterDays;
            delete tempCharge.lateFee;
        }

        tempCharge.firstDueDate = chargeForm.paymentDueOn;
        tempCharge.startDate = chargeForm.activeAfter;

        if (tempCharge.lateFees) {
            for (let i = 0; i < tempCharge.lateFees.length; i++) {
                if (tempCharge.lateFees[i].id === null) {
                    delete tempCharge.lateFees[i].id;
                }
                delete tempCharge.lateFees[i].recurringLateFeeBegins;
                delete tempCharge.lateFees[i].oneTimeLateFeeApplies;

                if (tempCharge.lateFees[i].lateFeeType === 'one-time') {
                    delete tempCharge.lateFees[i].recurringLateFeeInterval;
                    delete tempCharge.lateFees[i].recurringLateFeeIntervalValue;
                    delete tempCharge.lateFees[i].recurringLateFeeType;
                    delete tempCharge.lateFees[i].recurringLateFeeAmount;
                } else {
                    delete tempCharge.lateFees[i].oneTimeLateFeeType;
                    delete tempCharge.lateFees[i].oneTimeLateFeeAmount;
                }
            }
        }
        return [tempCharge];
    }

    /**
     * Create a Charge object from a ChargeForm object, only copying the most basic keys.
     * @param {ChargeForm} chargeForm
     * @returns {Charge}
     */
    protected static getBaseChargeFromChargeForm(chargeForm: ChargeForm) {
        return <Charge>{
            id: chargeForm.id,
            organizationId: chargeForm.organizationId,
            depositBankAccountId: chargeForm.depositBankAccountId,
            categoryId: chargeForm.categoryId,
            title: chargeForm.title,
            description: chargeForm.description,
            emailAppendMessage: chargeForm.emailAppendMessage,
            currency: chargeForm.currency,
            chargeAmount: chargeForm.chargeAmount,
            paid: chargeForm.paid,
            activeAfter: chargeForm.activeAfter,
            paymentDueOn: chargeForm.paymentDueOn,
            earlyBefore: chargeForm.earlyBefore,
            earlyDiscount: chargeForm.earlyDiscount,
            lateAfter: chargeForm.lateAfter,
            lateFee: chargeForm.lateFee,
            lateFees: chargeForm.lateFees,
            deletedAt: chargeForm.deletedAt,
            reason: chargeForm.reason,
            emailInvoice: chargeForm.emailInvoice,
        };
    }

    getRecurringTemplateDiff(templateIds: number[]): Observable<RecurringTemplateDiffResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeTemplates(jwt.orgId) + '/diff',
        ) + `/${templateIds[0]}/${templateIds[1]}`;

        return this._http.get(url).pipe(map((obj: any) => {
            return <RecurringTemplateDiffResponse>{
                template1: {
                    template: new RecurringChargeTemplate(obj.template1.template),
                    units: obj.template1.units.map((u) => new Unit(u)),
                },
                template2: {
                    template: new RecurringChargeTemplate(obj.template2.template),
                    units: obj.template2.units.map((u) => new Unit(u)),
                },
            };
        }));
    }

    public getReceivedCharges(organizationId?: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.ReceivedCharges(organizationId || jwt.orgId),
        );

        return this._http.get(url);
    }

    public hasARActivity(organizationId?: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.ARActivity(organizationId || jwt.orgId),
        );

        return this._http.get(url);
    }

    /**
     * Gets organization details from Core.
     * @param orgId
     * @param search
     * @param status
     * @param sortColumn
     * @param sortDirection
     * @param pageIndex
     * @param perPage
     * @param {{status?:string, charges?:number[], relations?:boolean}} options optional
     * @returns {Observable}
     */
    public getIssuedCharges(
            orgId: number,
            search: string = '',
            status: string = '',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: ChargeDatasourceOptions = {},
    ): Observable<ChargesResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const params = [];
        params.push('page=' + pageIndex);
        params.push('search=' + search);
        params.push('status=' + status);
        params.push('column=' + sortColumn);
        params.push('direction=' + sortDirection);
        params.push('perPage=' + perPage);
        if (typeof options !== 'undefined') {
            params.push('filters=' + JSON.stringify(options));
        }

        let url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.IssuedCharges(orgId || jwt.orgId));
        url = url + '?' + params.join('&');

        if (options.csv) {
            return this._http.get(url, {responseType: 'blob'});
        } else {
            return this._http.get(url).pipe(map((resp) => {
                return new ChargesResponse(resp);
            }));
        }
    }

    public getRecurringChargeTemplatesForUnit(unitId: number): Observable<RecurringChargeTemplate[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeTemplatesForUnit(jwt.orgId, unitId),
        );

        return this._http.get(url).pipe(map((res: Object[]) => {
            return res.map(rct => {
                return new RecurringChargeTemplate(rct);
            });
        }));
    }

    /**
     * Gets organization details from Core.
     * @param {number} organizationId
     * @param {{status?:string, charges?:number[], relations?:boolean}} options optional
     * @returns {Observable}
     */
    public getRecurringChargeTemplates(
            organizationId: number,
            options?: { status?: string, paging?: string, perPage?: string, page?: string },
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const filters: string[] = [];
        if (options.status && options.status.length) {
            filters.push('status=' + options.status);
        }
        if (options.paging) {
            filters.push('paging=true');
            filters.push('perPage=' + options.perPage);
            filters.push('page=' + options.page);
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeTemplates(organizationId || jwt.orgId) + (filters.length > 0
                        ? '?'
                        : '') + filters.join('&'),
        );

        return this._http.get(url);
    }

    public createTemplateCharges(templateId: number, expectedChargeId: number, unitId?: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeTemplate(jwt.orgId, templateId) + '/generate',
        );

        return this._http.post(url, JSON.stringify({
            expectedChargeId: expectedChargeId,
            unitId: unitId,
        })).pipe(map((response) => {
            if (!this.isJobRunning('RecurringTemplatesGeneration', expectedChargeId)) {
                this.monitorJobStatus({
                    job: 'RecurringTemplatesGeneration',
                    id: expectedChargeId,
                });
            }
            return response;
        }));
    }

    public isJobRunning(job, id) {
        return this.monitorIntervals.hasOwnProperty(job + '|' + id);
    }

    public getLastJobStatus(job, id) {
        return this.lastJobStatus[job + '|' + id];
    }

    public monitorJobStatus(job) {
        const key = job.job + '|' + job.id;
        this.lastJobStatus[key] = {
            running: true,
            status: 'Queued',
        };

        const checkFunction = () => {
            const url = Routes.MakeLegFiCoreUrl('/job-status');
            this._http.post(url, JSON.stringify(job))
                    .subscribe((response: any) => {
                        if (!response.running) {
                            clearInterval(this.monitorIntervals[key]);
                            delete this.monitorIntervals[key];
                            this.jobCompleted.emit({...job, ...response});
                        }
                        this.lastJobStatus[key] = response;
                    });
        };

        // Check the status once right away for quick jobs, then again every 5 seconds after
        setTimeout(checkFunction, 1000);
        this.monitorIntervals[key] = setInterval(checkFunction, 5000);
    }

    /**
     * Gets organization details from Core.
     * @param {number} organizationId
     * @param {number} templateId
     * @param {boolean} withCharges optional
     * @returns {Observable<RecurringChargeTemplate>}
     */
    public getRecurringChargeTemplate(
            organizationId: number,
            templateId: number,
            withCharges?: boolean,
            unitId?: number,
    ): Observable<RecurringChargeTemplate> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeTemplate(organizationId || jwt.orgId, templateId),
        );

        const params = [];
        if (unitId || withCharges) {
            if (withCharges) {
                params.push('with=charges');
            }
            if (unitId) {
                params.push('unitId=' + unitId);
            }
        }

        return this._http.get(url + '?' + params.join('&')).pipe(map(
                (response: Object) => {
                    return new RecurringChargeTemplate(response);
                },
        ));
    }

    public getChargesFromTemplate(templateId: number, status: string, unitId?: number): Observable<Charge[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeCharges(jwt.orgId, templateId),
        );

        const params = [
            'status=' + status,
        ];
        if (unitId) {
            params.push('unitId=' + unitId);
        }

        return this._http.get(url + '?' + params.join('&')).pipe(map(
                (response: Object[]) => {
                    return response.map(item => {
                        return new Charge(item);
                    });
                },
        ));
    }

    public pauseRecurringChargeTemplatesForUnit(
            organizationId: number,
            unitId: number,
            templateIds: number[],
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PauseRecurringChargeTemplatesForUnit(organizationId || jwt.orgId, unitId),
        );

        return this._http.post(url, JSON.stringify({templateIds: templateIds}));
    }

    public unpauseRecurringChargeTemplatesForUnit(
            organizationId: number,
            unitId: number,
            templateIds: number[],
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UnpauseRecurringChargeTemplatesForUnit(organizationId || jwt.orgId, unitId),
        );

        return this._http.post(url, JSON.stringify({templateIds: templateIds}));
    }

    /**
     * Gets organization details from Core.
     * @param {number} organizationId
     * @param {number[]} templateIds
     * @returns {Observable<RecurringChargeTemplate[]>}
     */
    public pauseRecurringChargeTemplates(
            organizationId: number,
            templateIds: number[],
    ): Observable<RecurringChargeTemplate[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeTemplatesPause(organizationId || jwt.orgId),
        );

        return this._http.post(url, JSON.stringify({templateIds: templateIds})).pipe(map(
                (response: Object[]) => {
                    return response.map((template) => {
                        return new RecurringChargeTemplate(template);
                    });
                },
        ));
    }

    /**
     * @param {number} organizationId
     * @param {{templateIds:number[], startDate:string, endDate:string}} requestBody
     * @returns {Observable<RecurringChargeTemplate[]>}
     */
    public unpauseRecurringChargeTemplates(
            organizationId: number,
            templateIds: number[],
    ): Observable<RecurringChargeTemplate[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeTemplatesUnpause(organizationId || jwt.orgId),
        );

        return this._http.post(url, JSON.stringify({templateIds: templateIds})).pipe(map(
                (response: Object[]) => {
                    return response.map((template) => {
                        return new RecurringChargeTemplate(template);
                    });
                },
        ));
    }

    /**
     * @param {number} templateId
     * @param {RecurringChargeTemplate} requestBody
     * @returns {Observable<RecurringChargeTemplate>}
     */
    public updateRecurringChargeTemplate(
            templateId: number,
            requestBody: RecurringChargeTemplate,
    ): Observable<RecurringChargeTemplate> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeTemplate(jwt.orgId, templateId),
        );

        return this._http.put(url, JSON.stringify(requestBody)).pipe(map(
                (response: Object) => {
                    return new RecurringChargeTemplate(response);
                },
        ));
    }

    public deleteUnitRecurringChargeTemplates(
            organizationId: number,
            unitId: number,
            templateIds: number[],
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeTemplatesForUnit(organizationId || jwt.orgId, unitId),
        ) + '?templateIds=' + templateIds.join(',');

        return this._http.delete(url);
    }

    /**
     * Gets organization details from Core.
     * @param {number} organizationId
     * @param {number[]} templateIds
     * @returns {Observable<RecurringChargeTemplate[]>}
     */
    public deleteRecurringChargeTemplates(
            organizationId: number,
            templateIds: number[],
    ): Observable<RecurringChargeTemplate[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RecurringChargeTemplates(organizationId || jwt.orgId),
        ) + '?templateIds=' + templateIds.join(',');

        return this._http.delete(url).pipe(map(
                (response: Object[]) => {
                    return response.map((template) => {
                        return new RecurringChargeTemplate(template);
                    });
                },
        ));
    }

    /**
     * Gets list of issued charges that can be copied from in charge-form modal.
     * @param {number} organizationId
     * @returns {Observable}
     */
    public getIssuedChargeCopyList(organizationId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IssuedChargesCopyList(organizationId || jwt.orgId),
        );

        return this._http.get(url);
    }

    /**
     * Gets list of issued charges that can be copied from in charge-form modal.
     * @param {number} organizationId
     * @returns {Observable}
     */
    public getIssuedChargeListByTitleSearch(organizationId: number, title: string): Observable<Charge[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IssuedChargesByTitle(organizationId || jwt.orgId) + '/search' + (title
                        ? '?title=' + encodeURIComponent(title)
                        : ''),
        );

        return this._http.get(url).pipe(map(
                (response: Object[]) => {
                    return response.map((charge) => {
                        return new Charge(charge);
                    });
                },
        ));
    }

    /**
     * @param {number} organizationId
     * @returns {any}
     */
    public getIssuedChargesOverview(organizationId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IssuedChargesOverview(organizationId || jwt.orgId),
        );

        return this._http.get(url);
    }

    /**
     * Gets organization details from Core.
     * @param {number} chargeId
     * @param {boolean} withHistory optional
     * @returns {Observable}
     */
    public getIssuedCharge(chargeId: number, withHistory?: boolean): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Charge(chargeId),
        ) + (withHistory ? '?with=history' : '');

        return this._http.get(url);
    }

    /**
     * Get filters used on issued charges.
     * @param organizationId
     * @returns {any}
     */
    public getChargeFilters(organizationId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IssuedChargesFilters(organizationId || jwt.orgId),
        );

        return this._http.get(url);
    }

    /**
     * Gets organization details from Core.
     * @param {number} userId
     * @param status
     * @returns {Observable}
     */
    public getUserCharges(userId: number, status?: string): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UserCharges(userId || jwt.id),
        ) + (status ? '?status=' + status : '');

        return this._http.get(url);
    }

    /**
     * Creates a charge set.
     * @param {any} requestBody
     * @param {boolean} queue optional
     * @returns {Observable}
     */
    public createCharges(requestBody: any, queue?: boolean): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        if (!requestBody.organizationId) {
            requestBody.organizationId = jwt.orgId;
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Charges,
        ) + (queue ? '?queue=true' : '');

        return this._http.post(url, JSON.stringify(requestBody)).pipe(
                map((res) => {
                    this._gtm.createInvoice({
                        payorType: requestBody.payorType,
                        orgId: requestBody.organizationId || jwt.orgId,
                        createdBy: jwt.id,
                    });
                    return res;
                }),
        );
    }

    /**
     * Preview invoice email of a proposed charge set.
     * @param {any} requestBody
     * @returns {Observable}
     */
    public previewCharges(requestBody: any): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        requestBody.organizationId = jwt.orgId;

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IssuedChargesPreview(),
        );

        return this._http.post(url, JSON.stringify(requestBody));
    }

    /**
     * Deletes remainder of 1-n charge sets.
     * Previous payments' sum becomes new charge balance, marked paid.
     * Deleted charge balance becomes deleted charge.
     * @param {number[]} chargeIds
     * @param {string} reason optional
     * @returns {Observable}
     */
    public forgiveCharges(chargeIds: number[], reason?: string): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const body = {
            organizationId: jwt.orgId,
            chargeIds: chargeIds,
            reason: (reason) ? reason : null,
        };
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IssuedChargesForgive(),
        );

        return this._http.post(url, JSON.stringify(body));
    }

    /**
     * Send an invoice to each distinct member for the specified charges.
     * @param {number[]} chargeIds
     * @param {boolean} overrideAutopayBlock optional
     * @returns {Observable}
     */
    public sendInvoices(chargeIds: number[], overrideAutopayBlock: boolean = false): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const body = {
            organizationId: jwt.orgId,
            chargeIds: chargeIds,
            overrideAutopayBlock: overrideAutopayBlock,
        };
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IssuedChargesSendInvoice(),
        );

        return this._http.post(url, JSON.stringify(body));
    }

    /**
     * @param body
     * @returns {any}
     */
    public createPaymentPlan(body: any): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        body.organizationId = jwt.orgId;

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IssuedChargesPaymentPlan(),
        );

        return this._http.post(url, JSON.stringify(body));
    }

    /**
     * Updates a charge set.
     * @param {number} chargeId
     * @param {Charge} charge
     * @returns {Observable}
     */
    public updateCharge(chargeId: number, charge: Charge): Observable<Object> {
        // Delete unneeded keys.
        if (!charge.earlyDiscount) {
            delete charge.earlyDiscount;
            delete charge.earlyBefore;
        }

        if (!charge.lateFee) {
            delete charge.lateFee;
            delete charge.lateAfter;
        }

        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        charge.organizationId = jwt.orgId;

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Charge(chargeId),
        );

        return this._http.put(url, JSON.stringify(charge));
    }

    /**
     * Updates a charge set.
     * @param {number} organizationId
     * @param {{organizationId?:number, charge:any, chargeIds:number[]}} bulkUpdateCharge
     * @returns {Observable}
     */
    public updateCharges(
            organizationId: number,
            bulkUpdateCharge: { organizationId?: number, charge: any, chargeIds: number[] },
    ): Observable<Object> {
        // Delete unneeded keys.
        if (!bulkUpdateCharge.charge.earlyDiscount) {
            delete bulkUpdateCharge.charge.earlyDiscount;
            delete bulkUpdateCharge.charge.earlyBefore;
        }

        if (!bulkUpdateCharge.charge.lateFee) {
            delete bulkUpdateCharge.charge.lateFee;
            delete bulkUpdateCharge.charge.lateAfter;
        }

        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        bulkUpdateCharge.organizationId = (organizationId) ? organizationId : jwt.orgId;

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Charges,
        );

        return this._http.patch(url, JSON.stringify(bulkUpdateCharge));
    }

    /**
     * Updates a charge set.
     * @param {number} organizationId
     * @param {{organizationId?:number, charge:any, chargeIds:number[]}} bulkUpdateCharge
     * @returns {Observable}
     */
    public updateChargeBalance(organizationId: number, chargeId: number): Observable<Object> {

        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Charge(chargeId) + '/update-balance',
        );

        return this._http.post(url, JSON.stringify({}));
    }

    /**
     * Deletes a charge set.
     * @param {number[]} chargeIds
     * @param {string} reason
     * @returns {Observable}
     */
    public reinstateCharges(chargeIds: number[], reason?: string): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const body = {
            organizationId: jwt.orgId,
            chargeIds: chargeIds,
            reason: (reason) ? reason : null,
        };

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IssuedChargesReinstate(),
        );

        return this._http.patch(url, JSON.stringify(body));
    }

    /**
     * Deletes a charge set.
     * @param {number} organizationId
     * @param {number[]} chargeIds
     * @param {string} reason
     * @returns {Observable}
     */
    public deleteCharges(organizationId: number, chargeIds: number[], reason?: string): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const orgId = (organizationId) ? organizationId : jwt.orgId;

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Charges + '?organizationId=' + orgId + '&chargeIds=' + chargeIds.join(',') + (
                        reason ? '&reason=' + reason : ''
                ),
        );

        return this._http.delete(url);
    }

    public getTemplateSchedule(templateId: number, unitId?: number): Observable<ExpectedCharge[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.ExpectedCharges(jwt.orgId, templateId),
        );

        const options = [];
        if (unitId) {
            options.push('unit=' + unitId);
        }

        return this._http.get(url + '?' + options.join('&')).pipe(
                map((response: Object[]) => {
                    return response.map(item => {
                        return new ExpectedCharge(item);
                    });
                }));
    }

    public getChargesForExpected(templateId: number, expectedChargeId: number, unitId?: number): Observable<{
        recurringCharges: RecurringCharge[],
        missing: RecurringChargeTemplateUnit[]
    }> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.ExpectedCharges(jwt.orgId, templateId) + '/' + expectedChargeId,
        );

        const options = [];
        if (unitId) {
            options.push('unit=' + unitId);
        }

        return this._http.get(url + '?' + options.join('&')).pipe(map((response: Object) => {
            return {
                recurringCharges: response['recurringCharges'].map(item => new RecurringCharge(item)),
                missing: response['missing'].map(item => new RecurringChargeTemplateUnit(item)),
            };
        }));
    }

    /**
     * @param {number} orgId
     * @param {number} unitId
     * @param search
     * @param status
     * @param sortColumn
     * @param sortDirection
     * @param pageIndex
     * @param perPage
     * @param options
     * @returns {Observable<Object>}
     */
    public getUnitCharges(
            orgId: number,
            unitId: number,
            search: string = '',
            status: string = '',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: ChargeDatasourceOptions = {},
    ): Observable<ChargesResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UnitCharges(orgId || jwt.orgId, unitId),
        );

        const params = [];
        params.push('page=' + pageIndex);
        params.push('search=' + search);
        params.push('status=' + status);
        params.push('column=' + sortColumn);
        params.push('direction=' + sortDirection);
        params.push('perPage=' + perPage);
        if (typeof options !== 'undefined') {
            params.push('filters=' + JSON.stringify(options));
        }

        return this._http.get(url + '?' + params.join('&')).pipe(map((resp) => {
            return new ChargesResponse(resp);
        }));
    }

    /**
     * Trigger a demo send to org admins.
     * @param {number} orgId
     * @returns {Observable<Object>}
     */
    public sendDemoInvoice(orgId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Organization(orgId || jwt.orgId) + '/demo/invoice',
        );

        return this._http.get(url);
    }

    /**
     * Download a single invoice/charge
     * @param chargeId
     * @returns {Observable<Blob>}
     */
    public downloadInvoice(chargeId: number): Observable<Blob> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Charge(chargeId) + '/download',
        );

        return this._http.get(url, {responseType: 'blob'});
    }
}
