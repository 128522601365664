import { Component, Input, OnInit } from '@angular/core';
import { PropertyManagementCompany } from '../../../../models/entities/property-management-company';

@Component({
    selector: 'app-sidenav-navigation',
    templateUrl: './sidenav-navigation.component.html',
    styleUrls: [
        '../../pmc-layout.component.scss',
        './sidenav-navigation.component.scss',
    ],
})
export class SidenavNavigationComponent implements OnInit
{
    @Input() pmc: PropertyManagementCompany;

    ngOnInit() {
        //
    }

    getRouterLink(endpoint: string) {
        return [`/pmc/${this.pmc.id}/${endpoint}`];
    }
}
