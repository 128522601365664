import { NgModule } from '@angular/core';
import { SidenavNavigationComponent } from './sidenav-navigation.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [
        RouterModule,
        MatIconModule,
        MatButtonModule,
    ],
    declarations: [SidenavNavigationComponent],
    providers: [],
    exports: [
        SidenavNavigationComponent,
    ],
})
export class SidenavNavigationModule
{
}
