import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FileUploader } from 'ng2-file-upload';
import { Routes } from '../config/routes';
import environment from '../../environments/environment';
import { LegFiJwtService } from '../services/auth/legfi-jwt.service';
import { JwtLegFiClaims } from '../services/auth/jwt-legfi-claims.model';
import { UploadedFile } from '../models/entities/uploaded-file';
import { OrganizationFile } from '../models/entities/organization-file';
import { UploadedFilesService } from '../services/uploaded-files.service';
import { FileUploaderService } from 'app/services/file-uploader.service';

@Component({
    selector: 'formly-field-file',
    template: `
        <label class="custom-file d-block mt-2 btn btn-outline-secondary" style="width: 120px;">
            <input
                type="file"
                class="custom-file-input"
                ng2FileSelect
                style="display: block;"
                [class.is-invalid]="showError"
                [formControl]="formControl"
                [formlyAttributes]="field"
                [uploader]="_uploader"
            />

            <span *ngIf="!fileLoading">
                <i class="fa fa-lg fa-paperclip"></i> Upload
            </span>
            <span *ngIf="fileLoading">
                <i class="fa fa-lg fa-spinner"></i>
            </span>
        </label>
        <div class="card mt-3">
            <div class="card-body p-0" *ngIf="attachments.length">
                <div>
                    <table class="table attachments mb-0">
                        <tr *ngFor="let item of attachments; let i = index; let last = last">
                            <td class="d-flex justify-content-between align-items-center">
                                <div>
                                    <img [src]="item.img" alt="upload"/>
                                    <span class="item-name ml-2">{{ item.name }}</span>
                                    <span class="item-size ml-2 text-secondary">({{ item.humanReadableSize }})</span>
                                    <i class="fa fa-lg fa-spinner ml-2" *ngIf="last && fileLoading"></i>
                                </div>
                                <span class="remove-item add-hover text-danger"
                                      *ngIf="!(last && fileLoading)"
                                      (click)="removeFile(i)">
                        Remove
                    </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div *ngIf="existingAttachments.length">
                <table class="table attachments my-0">
                    <tr>
                        <th>Existing Files</th>
                    </tr>
                    <tr *ngFor="let item of existingAttachments; let i = index">
                        <td class="d-flex justify-content-between">
                            <div>
                                <span class="item-name" [innerHTML]="item.url"></span>
                            </div>
                            <span class="remove-item add-hover text-danger"
                                  (click)="removeExistingFile(i, item.fileId)">
                            Remove
                        </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    `,
})
export class FormlyFieldFileComponent extends FieldType implements OnInit
{
    /** Array of attachments for this input; displayed on screen, but saved IDs send to parent */
    attachments: any[] = [];
    /** Existing attachments if editing; may want to remove */
    existingAttachments: { url: string; fileId: number }[] = [];
    /** If editing, there will be a submission id that we need for deleting files */
    submissionId: number = 0;
    /** Array of saved document ids to store as answer to this question */
    fileIds: number[] = [];
    /** typical jwt */
    jwt: JwtLegFiClaims = null;
    /** Bool if file is loading */
    fileLoading = false;
    /** the uploader */
    _uploader: FileUploader;

    constructor(
            private _uploadedFilesService: UploadedFilesService,
            private _fileUploaderService: FileUploaderService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.jwt = LegFiJwtService.read();
        this.initFileUploader();
        this.renderExistingFiles();

        if (this.model && this.model['submissionId']) {
            this.submissionId = this.model['submissionId'];
        }
    }

    /**
     * On edit, if there are existing files, display them for possible removal
     */
    renderExistingFiles() {
        // if there is an existing model, and there is a question matching this one in the model --
        // it has the existing images as <a> tags
        if (this.model && (typeof this.key === 'string' || typeof this.key === 'number') && this.model[this.key]) {
            const aTag = this.model[this.key];

            const aTagStrings = this._extractaTagsFromAnswerHack(aTag);
            const fileIds = this._extractFileIdsFromAnswerHack(aTag);

            fileIds.forEach((fileId: number, i: number) => {
                this.existingAttachments.push({
                    url: aTagStrings[i],
                    fileId: fileId,
                });
            });

            // init the fileId array with existing file ids on edit
            this.fileIds = fileIds;
        }
    }

    /**
     * Remove the file from attachments and from the file ids
     *
     * @param i
     */
    removeFile(i: number): void {
        this.attachments.splice(i, 1);
        this.fileIds.splice(i, 1);
        this.formControl.setValue(this.fileIds);
    }

    /** Delete an existing file  */
    removeExistingFile(index: number, removeId: number): void {
        this.existingAttachments.splice(index, 1);
        this.fileIds.splice(index, 1);
        this.formControl.setValue(this.fileIds);

        // can't unlink from the uploaded file bc, never had the link id to being with (file was created after answer)
        // so... just remove it from the array of file Ids in the answer here
    }

    initFileUploader(): void {
        if (this.jwt !== null) {
            // set up the upload endpoint
            this._uploader = this._fileUploaderService.initUploader({
                autoUpload: true,
                url: environment.LegFiCoreApi + Routes.LegFiCore.UploadFile(),
            });

            this._uploader.onErrorItem = (item, response, status, headers) => {
                this._uploader.clearQueue();
                // this.field.templateOptions.onUploadChange(null, this.formControl);
            };

            this._uploader.onCancelItem = (item, response, status, headers) => {
                this._uploader.clearQueue();
                // this.field.templateOptions.onUploadChange(null, this.formControl);
            };

            // when the file first gets added, before it starts auto upload
            this._uploader.onAfterAddingFile = (fileItem: any): any => {
                this.fileLoading = true;
                this.formControl.setErrors({isLoading: true});

                const file = fileItem;
                file.file.humanReadableSize = OrganizationFile.convertFileSizeToHumanReadable(
                        file.file.size,
                        2,
                );

                const type = file.file.type;
                file.file.img = this._parseFileUrl(type);

                this.attachments.push(file.file);
            };

            // when the file is done auto uploading
            this._uploader.onCompleteItem = (
                    fileItem: any,
                    response: string,
                    status: number,
            ) => {
                const result = JSON.parse(response) as UploadedFile;
                if (status === 200) {
                    this.fileIds.push(result.id);
                    this.formControl.setValue(this.fileIds);
                    this.fileLoading = false;
                    this.formControl.setErrors(null);
                } else {
                    console.error(status);
                }
            };
        }
    }

    /**
     * Hacky way to get the file ids from existing files related to a form submission answer; hackety hack
     *
     * @param aTagString - string we're searching through (lots of concat <a> tags w info in them)
     */
    _extractFileIdsFromAnswerHack(aTagString: string): number[] {
        // get all the indexOf string starting with 'id='
        const idIndex: number[] = [];
        const regex = /id='/gi;
        let result: RegExpExecArray;
        while ((result = regex.exec(aTagString))) {
            idIndex.push(result.index);
        }

        // get all the indexOf string starting with 'target='
        const tregex = /target='/gi;
        let tresult: RegExpExecArray;
        const tidIndex = [];
        while ((tresult = tregex.exec(aTagString))) {
            tidIndex.push(tresult.index);
        }

        // haaaaack......
        const fileIds: number[] = [];
        idIndex.forEach((id: number, i: number) => {
            const returningId = aTagString.substring(idIndex[i] + 4, tidIndex[i] - 2);
            fileIds.push(parseInt(returningId, 10));
        });

        return fileIds;
    }

    /**
     * Hacky way to get the distinct atags out of the long a tag string
     *
     * @param aTagString - string we're searching through (lots of concat <a> tags w info in them)
     */
    _extractaTagsFromAnswerHack(aTagString: string): string[] {
        // get all the indexOf string starting with '<a'
        const idIndex: number[] = [];
        const regex = /<a/gi;
        let result: RegExpExecArray;
        while ((result = regex.exec(aTagString))) {
            idIndex.push(result.index);
        }

        // get all the indexOf string starting with '<a/>'
        const aidIndex: number[] = [];
        const aregex = /\/a>/gi;
        let aresult: RegExpExecArray;
        while ((aresult = aregex.exec(aTagString))) {
            aidIndex.push(aresult.index);
        }

        // haaaaack......
        const urlStrings: string[] = [];
        idIndex.forEach((id: number, i: number) => {
            const aTag = aTagString.substring(idIndex[i], aidIndex[i] + 3);
            urlStrings.push(aTag);
        });

        return urlStrings;
    }

    /**
     * Helper to figure out what file type it is on upload
     *
     * @param type
     */
    private _parseFileUrl(type: any): string {
        let url = '/assets/images/app/icons/file_icons/';

        if (type.search('gif') > 0) {
            url += 'gif.svg';
        } else if (type.search('png') > 0) {
            url += 'png.svg';
        } else if (type.search('pdf') > 0) {
            url += 'pdf.svg';
        } else if (type.search('word') > 0) {
            url += 'doc.svg';
        } else if (
                type.search('powerpoint') > 0 ||
                type.search('ppt') > 0
        ) {
            url += 'ppt.svg';
        } else if (
                type.search('excel') > 0 ||
                type.search('xls') > 0
        ) {
            url += 'xls.svg';
        } else if (type.search('zip') > 0) {
            url += 'zip.svg';
        } else {
            url += 'jpg.svg'; // generic
        }

        return url;
    }
}
