import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PropertyManagementCompany } from '../../models/entities/property-management-company';

@Injectable({
    providedIn: 'root',
})
export class PropertyManagementCompanyDataService
{
    private pmcSubject$: BehaviorSubject<PropertyManagementCompany> = new BehaviorSubject<PropertyManagementCompany>(null);

    public get pmc$(): Observable<PropertyManagementCompany> {
        return this.pmcSubject$.asObservable();
    }

    public get pmcId(): number {
        return this.pmcSubject$.getValue()?.id;
    }

    public switch(pmc: PropertyManagementCompany): PropertyManagementCompany {
        this.pmcSubject$.next(pmc);

        return this.pmcSubject$.getValue();
    }

    public clear() {
        this.pmcSubject$.next(null);
    }
}
