import { RouterModule, Routes } from '@angular/router';
import { PmcLayoutComponent } from './pmc-layout.component';
import { PermissionsGuard } from '../../common/auth/permissions.guard';
import { RouteModules } from '../../enums/route-modules.enum';

const pmcLayoutRoutes: Routes = [
    {
        path: 'pmc',
        component: PmcLayoutComponent,
        children: [
            {
                canActivate: [PermissionsGuard],
                path: 'list',
                loadChildren: () => import('./property-management-companies/property-management-companies.module').then(m => m.PropertyManagementCompaniesModule),
                data: {module: RouteModules.GROUPS},
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/pmc/list',
            },
        ],
    },
    {
        path: 'pmc/:pmcId',
        component: PmcLayoutComponent,
        children: [
            {
                canActivate: [PermissionsGuard],
                path: 'dashboard',
                loadChildren: () => import('./property-management-dashboard/property-management-dashboard.module').then(m => m.PropertyManagementDashboardModule),
                data: {module: RouteModules.DASHBOARD},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'communities',
                loadChildren: () => import('./property-management-organizations/property-management-organizations.module').then(m => m.PropertyManagementOrganizationsModule),
                data: {module: RouteModules.COMMUNITIES},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'requests',
                loadChildren: () => import('./aggregate-request-forms/aggregate-request-forms.module').then(m => m.AggregateRequestFormsModule),
                data: {module: RouteModules.REQUEST_FORMS},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'violations',
                loadChildren: () => import('./aggregate-violations/aggregate-violations.module').then(m => m.AggregateViolationsModule),
                data: {module: RouteModules.VIOLATION},
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard',
            },
        ],
    },
];

export const pmcLayoutRouting = RouterModule.forChild(pmcLayoutRoutes);
