<button mat-icon-button disableRipple
        class="animated fadeIn"
        [attr.aria-label]="'PayHOA Notifications'"
        (click)="openNotifications()">
    <mat-icon matBadgeSize="small"
              matBadgePosition="after"
              [matBadge]="0"
              [svgIcon]="'v2:fi-br-bell'">
    </mat-icon>
</button>

<button #appMenuTrigger="matMenuTrigger"
        mat-button disableRipple
        class="animated fadeIn"
        [matMenuTriggerFor]="appMenu"
        [attr.aria-label]="'PayHOA App Menu'">
    <span class="profile-initials-icon"><span>{{ pmc.initials }}</span></span>
    <span class="profile-name">{{ pmc.name }} <mat-icon>arrow_drop_down</mat-icon></span>
</button>
<mat-menu #appMenu="matMenu" xPosition="before" class="app-header-menu">
    <span class="app-header-menu-label">
        {{ pmc.name }}
        <span class="description-text">{{ 'All Communities' }}</span>
    </span>

    <mat-divider></mat-divider>

    <button *ngIf="jwt.memberId" [routerLink]="['/app/settings/user']" mat-menu-item>{{ 'Account Settings' }}</button>
    <button *ngIf="jwt.admin || jwt.superUser" [routerLink]="['/app/make-payment']" mat-menu-item>{{ 'Make a Payment' }}</button>
    <button *ngIf="hasSupportPermission" (click)="sendIntercomMessage()" mat-menu-item>{{ 'Submit an Issue' }}</button>
    <button (click)="logout()" mat-menu-item>{{ 'Sign Out' }}</button>

    <mat-divider></mat-divider>

    <form class="app-organization-switcher" (click)="stopClickFromClosing($event)">
        <mat-form-field appearance="outline">
            <mat-icon [svgIcon]="'v2:fi-br-search'" matPrefix></mat-icon>
            <input type="text"
                   aria-label="Search"
                   matInput
                   tabindex="0"
                   [placeholder]="'Find Organization....'"
                   [formControl]="organizationSearch">
            <mat-spinner *ngIf="isSearchingOrganizations" [diameter]="15" matSuffix></mat-spinner>
        </mat-form-field>
        <cdk-virtual-scroll-viewport appendOnly itemSize="60" class="viewport">
            <button *cdkVirtualFor="let result of filteredResults" (click)="goToOrganization(result); appMenuTrigger.closeMenu()" mat-menu-item>
                <span class="profile-initials-icon"><span>{{ result.initials }}</span></span>
                <span class="profile-details">
                <span class="profile-name">{{ result.name }}</span>
                <span class="description-text">{{ result.id }}</span>
            </span>
            </button>
        </cdk-virtual-scroll-viewport>
    </form>
</mat-menu>
