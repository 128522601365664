<a mat-button disableRipple
   class="animated animated-delay fadeInTop"
   [attr.aria-label]="'Property Management Dashboard'"
   [attr.data-intercom-target]="'Dashboard Page Menu'"
   [routerLink]="getRouterLink('dashboard')"
   [routerLinkActive]="['mat-button-active']">
    <span class="d-flex align-items-center">
        <mat-icon [svgIcon]="'v2:fi-br-chart-mixed'"></mat-icon> {{ 'Dashboard' }}
    </span>
</a>

<a mat-button disableRipple
   class="animated animated-delay fadeInTop"
   [attr.aria-label]="'Communities under Managed Properties'"
   [attr.data-intercom-target]="'Communities Page Menu'"
   [routerLink]="getRouterLink('communities')"
   [routerLinkActive]="['mat-button-active']">
    <span class="d-flex align-items-center">
        <mat-icon [svgIcon]="'v2:fi-br-building'"></mat-icon> {{ 'Communities' }}
    </span>
</a>

<a mat-button disableRipple
   class="animated animated-delay fadeInTop"
   [attr.aria-label]="'Requests from Managed Properties'"
   [attr.data-intercom-target]="'Requests Page Menu'"
   [routerLink]="getRouterLink('requests')"
   [routerLinkActive]="['mat-button-active']">
    <span class="d-flex align-items-center">
        <mat-icon [svgIcon]="'v2:fi-br-question'"></mat-icon>
        {{ 'Requests' }}
    </span>
</a>

<a mat-button disableRipple
   class="animated animated-delay fadeInTop"
   [attr.aria-label]="'Violations from Managed Properties'"
   [attr.data-intercom-target]="'Violations Page Menu'"
   [routerLink]="getRouterLink('violations')"
   [routerLinkActive]="['mat-button-active']">
    <span class="d-flex align-items-center">
        <mat-icon [svgIcon]="'v2:fi-br-triangle-warning'"></mat-icon>
        {{ 'Violations' }}
    </span>
</a>


