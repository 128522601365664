import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonType } from '../button/button.component';
import { isArray } from 'chart.js/helpers';

export interface JumbotronAction {
    label: string;
    route: string;
    click?: (event: MouseEvent) => void;
    icon?: string;
    type?: ButtonType;
}

@Component({
    selector: 'app-jumbotron',
    templateUrl: './jumbotron.component.html',
    styleUrls: ['./jumbotron.component.scss']
})
export class JumbotronComponent implements OnChanges {
    @Input() svgIcon;
    @Input() icon;
    /** TODO: Replace empty states (smiley inbox) with svg icons across app */
    @Input() showEmptyImg = false;

    @Input() headerText: string;
    @Input() subtext: string;
    @Input() actions: JumbotronAction[];
    @Input() isLead = false;
    @Input() classList: string | string[] | Set<string> | {
        [klass: string]: any;
    };

    buttons: JumbotronAction[] = [];

    readonly defaultAction: JumbotronAction = {
        label: 'Add',
        route: '/',
        icon: 'add',
        type: 'primary',
    };

    get titleForJumbotron() {
        return this.headerText ? this.headerText : 'Let\'s get started!';
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('actions' in changes && isArray(this.actions)) {
            this.buttons = this.actions.map((action) => {
                return {
                    ...this.defaultAction,
                    ...action,
                };
            });
        }
    }
}
