<mat-sidenav-container class="app-layout-container" [class.mobile-layout]="isInMobileMode">
    <mat-sidenav position="start" [mode]="isInMobileMode ? 'over' : 'side'" [opened]="!isInMobileMode">
        <div class="app-logo">
            <a [routerLink]="['/pmc']">
                <img class="img" alt="{{ 'Home' }}" [attr.src]="appLogoSrc"/>
            </a>
        </div>

        <app-sidenav-navigation *ngIf="pmc$ | async as pmc" [pmc]="pmc"></app-sidenav-navigation>
    </mat-sidenav>

    <mat-toolbar class="app-header">
        <app-header-search></app-header-search>
        <span class="mat-toolbar-spacer"></span>
        <app-header-menu *ngIf="pmc$ | async as pmc" [pmc]="pmc"></app-header-menu>
    </mat-toolbar>

    <div class="app-content">
        <router-outlet></router-outlet>
    </div>

    <mat-sidenav #rightSidenav position="end" disableClose autoFocus="false">
        <!-- TODO: createComponent to project Components (similar to mat-dialog) -->
    </mat-sidenav>
</mat-sidenav-container>
