import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Routes } from '../../config/routes';
import { map } from 'rxjs/operators';
import { PropertyManagementCompany, PropertyManagementCompanyResponse } from '../../models/entities/property-management-company';
import { OrganizationResponse } from '../../models/entities/organization';
import { PropertyManagementCompanyDataService } from './property-manager-company-data.service';

@Injectable({
    providedIn: 'root',
})
export class PropertyManagementCompanyService {
    constructor(
            private _http: ApplicationHttpClient,
            private _data: PropertyManagementCompanyDataService,
    ) {
    }

    public getPropertyManagementCompanies(
        search: string = '',
        sortColumn: string = 'name',
        sortDirection: string = 'asc',
        pageIndex: number = 1,
        perPage: number = 100,
    ){
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        let url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.PropertyManagementCompanies);

        const params = [];
        params.push('page=' + pageIndex);
        params.push('search=' + search);
        params.push('column=' + sortColumn);
        params.push('direction=' + sortDirection);
        params.push('per_page=' + perPage);

        url = url + '?' + params.join('&');

        return this._http.get(url).pipe(map((res) => {
            return new PropertyManagementCompanyResponse(res);
        }));
    }

    public getPropertyManagementCompany(pmcId: number) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.PropertyManagementCompany(pmcId));
        return this._http.get(url).pipe(map((res: any) => new PropertyManagementCompany(res)));
    }

    public getPropertyManagementOrganizations(
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'asc',
            pageIndex: number = 1,
            perPage: number = 100,
    ){
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        let url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.PropertyManagementOrganizations(this._data.pmcId));

        const params = [];
        params.push('page=' + pageIndex);
        params.push('search=' + search);
        params.push('column=' + sortColumn);
        params.push('direction=' + sortDirection);
        params.push('per_page=' + perPage);

        url = url + '?' + params.join('&');

        return this._http.get(url).pipe(map((res) => {
            return new OrganizationResponse(res);
        }));
    }
}
